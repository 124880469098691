
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$openvidu-components-primary: mat-palette($mat-blue-grey, 50, 300);
$openvidu-components-accent: mat-palette($mat-amber, 500, 700, A100);

// The warn palette is optional (defaults to red).
$openvidu-components-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$openvidu-components-theme: mat.define-light-theme((
  color: (
    primary: $openvidu-components-primary,
    accent: $openvidu-components-accent,
    warn: $openvidu-components-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($openvidu-components-theme);


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; overflow: hidden;}
body { margin: 0; font-family: 'Roboto','RobotoDraft',Helvetica,Arial,sans-serif;background-color: #e6e6e6;}

// Custom openvidu-components styles
:root {
  --ov-primary-color: #303030;
  --ov-secondary-color: #3e3f3f;
  --ov-tertiary-color: #598eff;
  --ov-warn-color: #EB5144;
  --ov-accent-color: #ffae35;
  --ov-light-color: #e6e6e6;

  --ov-logo-background-color: #3a3d3d;
  --ov-text-color: #ffffff;

  --ov-panel-text-color: #1d1d1d;
  --ov-panel-background: #ffffff;

  --ov-buttons-radius: 50%;
  --ov-leave-button-radius: 10px;
  --ov-video-radius: 5px;
  --ov-panel-radius: 5px;
}

#branding-logo {
  content : url("assets/images/logo.png") !important;
  max-width: 100% !important;
  min-height: 50px !important;
  background: transparent !important;
}

.prejoin-toolbar {
  max-height: 60px !important;
  height: 60px !important;
}

.sidenav-container {
  padding-top: 4rem !important;
}

#prejoin-container {
  padding-top: 3rem;
}

#background-effects-btn {
  display: none !important;
}

.panel-container {
  margin-right: 20px !important;
  margin-top: 4rem !important;
  margin-left: 20px !important;
  margin-bottom: 2rem !important;
}

.container {
  max-width: 100% !important;
}

.height-40 {
  height: 40px;
}

h1,h2,h3,h4,h5 {
  line-height: normal !important;
}

.box-shadow-1 {
  box-shadow: 0 2px 6px 0 rgba(62, 57, 107, 0.1) !important;
}